.mat-flat-button {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border-radius: 7px !important;
}

.micro-button{
  padding: 0!important;
  min-width: 24px!important;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  line-height: 24px!important;
}
