.monitoring-table {
  border-radius: 10px 10px 0 0;
  overflow: hidden;

  td,
  td.mat-cell,
  th,
  th.mat-header-cell {
    font-size: 0.875rem;
    border-bottom: 1px solid $tableBorderColor;
  }

  th {
    border: none;
    color: $mainFontColor;
    background: $tableHeaderBackgroundColor;
    font-weight: 500;
    border-top: 1px solid $tableBorderColor;
  }

  td {
    color: $mainFontColor;
    font-weight: 400;
  }

  tr {
    height: auto;
    min-height: 48px;
  }

  .mat-sort-header-button {
    text-align: left;
  }
}
