@import "abstract";

.hml-form-field {
  .mat-form-field-infix {
    color: $mainFontColor;
    background: transparent;
  }

  .mat-form-field-flex {
    display: inline-flex;
  }

  .mat-form-field-wrapper {
    border-radius: 4px;
    background: $colorPurpleLightBg;
    border-color: transparent;
  }

  textarea,
  textarea.mat-input-element {
    padding: 0.5rem;
    box-sizing: border-box;
    height: auto;
  }

  input {
    background: $colorPurpleLightBg;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .mat-form-field-prefix {
    .mat-icon {
      line-height: 24px;
    }
  }

  .mat-form-field-label {
    text-transform: none;
    font-weight: 400;
    font-size: 0.75rem;
    color: $secondaryFontColor;
  }

  .mat-select-arrow {
    border: none;
    transform: rotate(270deg);
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-family: "fleet" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    &::before {
      content: "\e91c";
    }
  }

  &-autoheight {
    .mat-form-field-infix {
      height: auto;
    }
  }

  .mat-form-field-suffix {
    cursor: pointer;

    .mat-icon-button .mat-button-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .mat-date-range-input {
    height: 100%;
    padding: 0 0.5rem;
  }

  .mat-date-range-input-container {
    height: 100%;
  }

  .mat-datepicker-toggle {
    .mat-datepicker-toggle-default-icon {
      width: inherit;
    }
  }

  .mat-form-field-type-mat-date-range-input {
    .mat-icon-button {
      width: inherit;
      height: inherit;
    }
  }
}

.ddd-entity-crud-search-field {
  .mat-form-field-flex {
    align-items: center;
    height: 38px;
  }
  .mat-form-field-wrapper {
    border: none;
    width: 350px;
  }
}

.ddd-entity-crud-form-field {
  .mat-form-field-wrapper {
    border: none;
    width: 180px;
  }

  &-fixed-height {
    .mat-form-field-flex {
      align-items: center;
      height: 36px;
    }
  }
}
