@import "abstract";

.hml-tabs {
  .mat-tab-label {
    color: $mainFontColor;

    &.mat-tab-label-active {
      opacity: 1;
      color: white;
      background-color: $highlightColor;
    }
  }

  .mat-tab-header {
    border: 1px solid #F5F5F5;

    .mat-ink-bar {
      background-color: $highlightColor;
    }
  }
}

.monitoring-tabs {
  .mat-tab-header {
    border: none;

    .mat-ink-bar {
      background-color: $highlightColor;
    }
  }
}
