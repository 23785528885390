@font-face {
    font-family: 'Hyundai Sans Text';
    src: url('HyundaiSansText-BoldItalic.eot');
    src: local('Hyundai Sans Text Bold Italic'), local('HyundaiSansText-BoldItalic'),
        url('HyundaiSansText-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansText-BoldItalic.woff2') format('woff2'),
        url('HyundaiSansText-BoldItalic.woff') format('woff'),
        url('HyundaiSansText-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hyundai Sans Text';
    src: url('HyundaiSansText-Bold.eot');
    src: local('Hyundai Sans Text Bold'), local('HyundaiSansText-Bold'),
        url('HyundaiSansText-Bold.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansText-Bold.woff2') format('woff2'),
        url('HyundaiSansText-Bold.woff') format('woff'),
        url('HyundaiSansText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hyundai Sans Text';
    src: url('HyundaiSansText-Italic.eot');
    src: local('Hyundai Sans Text Italic'), local('HyundaiSansText-Italic'),
        url('HyundaiSansText-Italic.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansText-Italic.woff2') format('woff2'),
        url('HyundaiSansText-Italic.woff') format('woff'),
        url('HyundaiSansText-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hyundai Sans Text';
    src: url('HyundaiSansText-MediumItalic.eot');
    src: local('Hyundai Sans Text Medium Italic'), local('HyundaiSansText-MediumItalic'),
        url('HyundaiSansText-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansText-MediumItalic.woff2') format('woff2'),
        url('HyundaiSansText-MediumItalic.woff') format('woff'),
        url('HyundaiSansText-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hyundai Sans Text';
    src: url('HyundaiSansText-Medium.eot');
    src: local('Hyundai Sans Text Medium'), local('HyundaiSansText-Medium'),
        url('HyundaiSansText-Medium.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansText-Medium.woff2') format('woff2'),
        url('HyundaiSansText-Medium.woff') format('woff'),
        url('HyundaiSansText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hyundai Sans Text';
    src: url('HyundaiSansText.eot');
    src: local('Hyundai Sans Text'), local('HyundaiSansText'),
        url('HyundaiSansText.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansText.woff2') format('woff2'),
        url('HyundaiSansText.woff') format('woff'),
        url('HyundaiSansText.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

