@mixin common($primary-color,$accent-color,$warn-color, $font-family) {
  .bg-primary {
    background-color: $primary-color;
  }

  .bg-accent {
    background-color: $accent-color;
  }

  .bg-warn {
    background-color: $warn-color;
  }

  .color-primary {
    color: $primary-color;
  }

  .color-accent {
    color: $accent-color;
  }

  .color-warn {
    color: $warn-color;
  }

  .mat-body, .mat-body-1, .mat-typography {
    font: 400 12px/20px $font-family;

  }

}
