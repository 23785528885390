@use "sass:math";

.fine-map-cluster {
  display: flex;
  align-items: center;
  text-align: center;
}

// SLIDER

$periodSliderPointerWidth: 12px;
$periodSliderPointerHeight: 24px;

$periodSliderPointerInnerBarWidth: 2px;
$periodSliderPointerInnerBarHeight: 14px;

$periodSliderTickBarWidth: 2px;
$periodSliderTickBarHeight: 8px;

$periodSliderBarHeight: 32px;
$periodSliderBorderWidth: 1px;

$periodSliderRootHeight: $periodSliderBarHeight + ($periodSliderBorderWidth * 2);

.fines-map-period-slider {
  padding: 2.5rem 0 0;
  .noUi-pips {
    top: -36px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      display: block;
      height: 1px;
      background: $secondaryFontColor;
    }
  }

  .noUi-marker-normal {
    display: none;
  }

  .noUi-marker-large {
    top: 0;
    width: 2px;
    height: 8px;
  }

  .noUi-value {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    color: $mainFontColor;
    top: 0.25rem;
  }

  .noUi-target {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .noUi-horizontal {
    height: $periodSliderRootHeight;
  }

  .noUi-base {
    height: $periodSliderRootHeight;
    background: $colorPurpleLightBg;
    border: $periodSliderBorderWidth solid $secondaryFontColor;
    border-radius: 4px;

    .noUi-connect {
      border: $periodSliderBorderWidth solid $secondaryFontColor;
      border-radius: 4px;
      background: rgba($color: $colorViolet, $alpha: 0.2);
      top: -1px;
      height: $periodSliderRootHeight + 2px;
    }

    .noUi-handle {
      width: $periodSliderPointerWidth;
      height: $periodSliderPointerHeight;
      top: math.div(($periodSliderRootHeight - $periodSliderPointerHeight), 2);
      right: -7px;
      border-radius: 2px;
      background: $colorViolet;
      border: none;
      box-shadow: none;

      &.noUi-active {
        &::before,
        &::after {
          background: white;
        }
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: $periodSliderPointerInnerBarWidth;
        height: $periodSliderPointerInnerBarHeight;
        border-radius: 2px;
        border: none;
        background: white;
        position: absolute;
        top: math.div(($periodSliderPointerHeight - $periodSliderPointerInnerBarHeight), 2);
      }

      &::before {
        left: math.div(($periodSliderPointerWidth - $periodSliderPointerInnerBarWidth * 3), 2);
        right: unset;
      }

      &::after {
        right: math.div(($periodSliderPointerWidth - $periodSliderPointerInnerBarWidth * 3), 2);
        left: unset;
      }
    }
  }
}
