/* Font Families */
$font-family-base: "Hyundai Sans Text", "SF Compact Display", sans-serif;
$font-family-head: "Hyundai Sans Head", $font-family-base;

$mainFontSize: 14px;
$mainFontColor: #222222;
$secondaryFontColor: #a3aed0;
$mutedFontColor: #606574;
$mainBackgroundColor: #f4f6ff;
$primaryButtonBackgroundColor: #7065e6;
$pageTopOffset: 24px;
$colorDanger: #e56593;
$colorBackButton: #212121;

$colorViolet: #4933c9;
$colorViolet4: #959bae;
$colorBlueGreen: #32c9c2;
$colorWarna: #f1b61d;
$colorPurpleLightBg: #f4f6ff;
$colorDarkBlue: #002e69;
$colorCaribbeanGreen: #05cd99;
$highlightColor: #5c4fe2;
$colorGreen: #05cd99;
$colorGreenLite: #e8fbf2;
$colorFauxSnow: #fff7f7;
$colorLiteViolet: #9d8fe1;
$colorRed: red;
$colorYellow: #ffb800;
$colorFauxAliceBlue: #e9f4fc;
$colorFauxSnow: #fff7f7;
$colorMediumLightRed: #ee5d50;
$colorPaleBlue: #f4f7fe;
$colorLiteViolet: #9d8fe1;
$colorLightYellow: #ffb800;
$colorWhitesmoke: #f5f5f5;
$colorGoshawkGrey: #444444;
$colorLightGray: #959BAE;

/* Primary Btn Colors */
$primaryBtnBg: $colorViolet;
$primaryBtnBgHover: $secondaryFontColor;
$primaryBtnBgDisabled: $colorPurpleLightBg;

$primaryBtnColor: white;
$primaryBtnColorHover: white;
$primaryBtnColorDisabled: $secondaryFontColor;

$primaryBtnBorder: $colorViolet;
$primaryBtnBorderHover: $secondaryFontColor;
$primaryBtnBorderDisabled: $colorPurpleLightBg;

/* Secondary Btn Colors */
$secondaryBtnBg: white;
$secondaryBtnBgHover: $colorPurpleLightBg;
$secondaryBtnBgDisabled: $colorPurpleLightBg;

$secondaryBtnColor: $mainFontColor;
$secondaryBtnColorHover: $mainFontColor;
$secondaryBtnColorDisabled: $secondaryFontColor;

$secondaryBtnBorder: $colorViolet;
$secondaryBtnBorderHover: $secondaryFontColor;
$secondaryBtnBorderDisabled: $colorPurpleLightBg;

$colorBtnBgPaleNavy: #f4f7fe;


$cardBoxShadowColor: #1303831a;
$cardBg: white;
$cardBorderColor: #eeeeee;

/* Menu */
$menuBg: white;

/* Nav Bar */
$navbarBg: white;
$notificationBadgeIcon: red;

/* Toggle btns */
$toggleBtnShadowColor: #0f56b333;

$radioBtnBorderColor: #bdbcdb;

/* Message Bar */

$messageBarTypes: (
  warn: (
    bg: #ffc6da,
    textColor: #444444,
    iconColor: #e56593,
    iconWrapperBg: #ffffff,
  ),
  good: (
    bg: #95d3b2,
    textColor: #444444,
    iconColor: $colorGreen,
    iconWrapperBg: #ffffff,
  ),
);

/* Tables */
$mainTableBackgroundColor: #ffffff;
$secondaryTableBackgroundColor: #fafaff;
$tableHeaderBackgroundColor: $colorWhitesmoke;
$tableBorderColor: #e8e8e8;
