.hml-slide-toggle,
.hml-slide-toggle.mat-slide-toggle {
  .mat-slide-toggle-ripple {
    display: none;
  }

  .mat-slide-toggle-bar {
    width: 48px;
    height: 20px;
    background-color: $secondaryFontColor;
    border-radius: 12px;
  }

  .mat-slide-toggle-thumb-container {
    width: 16px;
    height: 16px;
    top: 2px;
    left: 2px;
  }

  .mat-slide-toggle-thumb {
    width: 16px;
    height: 16px;
    background-color: white;
  }

  &.mat-checked {
    .mat-slide-toggle-thumb-container {
      transform: translate3d(28px, 0, 0);
    }

    .mat-slide-toggle-thumb {
      background-color: white;
    }

    .mat-slide-toggle-bar {
      background-color: $colorViolet;
    }
  }
}
