.geozone-map-search-form-field.hml-form-field {
  .mat-form-field-wrapper,
  input {
    background: white;
  }

  .mat-form-field-wrapper {
    box-shadow: 0px 0px 12px 0px $cardBoxShadowColor;
  }
}
