.hml-expansion-panel {
  .mat-expansion-panel-header {
    padding: 0.75rem;
    background: $colorPurpleLightBg;
    font-family: $font-family-head;
    font-size: 1rem;
    font-weight: 500;
  }

  .mat-expansion-panel-body {
    padding: 0.625rem 1rem 1.5rem;
  }
}
