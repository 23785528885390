.car-scheduler-editor {
  .hml-dropdown-like-text {
    .mat-select-arrow {
      color: $secondaryFontColor;
      border: none;
      transform: rotate(90deg);
      font-size: 1.5rem;
      width: 0.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      font-family: "fleet" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      position: relative;

      &::before {
        content: "\e91c";
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        display: block;
      }
    }
  }
}
