@import 'abstract';

.hml-checkbox {
  .mat-checkbox-frame {
    border: 1px solid $secondaryFontColor;
    border-radius: 2px;
  }

  .mat-checkbox-layout {
    white-space: inherit;
  }

  .mat-checkbox-checkmark {
    top: 2px;
    left: 2px;
    width: 75%;
  }

  .mat-ripple {
    display: none;
  }

  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background: $colorViolet;
    }
  }

  &:disabled, &.disabled, &.mat-checkbox-disabled {
    pointer-events: none;

    .mat-checkbox-frame {
      border-color: $colorPurpleLightBg;
    }

    &:not(.mat-checkbox-checked).mat-checkbox-background {
      background: $colorPurpleLightBg;
    }
  }

  &-reversed {
    .mat-checkbox-layout {
      flex-direction: row-reverse;
    }

    .mat-checkbox-inner-container {
      margin-right: auto;
      margin-left: 8px;
    }
  }

  .mat-checkbox-layout .mat-checkbox-label {
    line-height: inherit;
  }
}
