@mixin fleet-table($primary-color, $primary-color-50) {
  table:not(.table-no-hover) {
    tbody {
      tr:hover {
        background-color: lighten($primary-color-50, 8%);

        td {
          color: $primary-color !important;
        }
      }
    }
  }

  table.table-no-borders {
    mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
      border-color: transparent;
    }
  }
}
