@import "abstract";

.fab-nav {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;

  .mat-fab {
    background-color: $mainFontColor;
    color: white;
    box-shadow: 2px 4px 4px 0px #00000040;
  }

  &__menu {
    &.mat-menu-panel {
      min-width: 274px;
      background: white;
      border-radius: 8px;
    }

    .mat-menu-item {
      font-size: .875rem;
      font-weight: 700;
      line-height: normal;
      color: $mainFontColor;

      &:hover {
        background: $colorViolet;
        color: white;
      }
    }
  }
}
