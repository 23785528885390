@mixin mat-snack-bar-container {
  max-width: 90vw !important;
  min-width: 90vw !important;
  padding: 0 16px !important;
  min-height: 40px !important;
}


@mixin mat-snackbars($primary-color, $accent-color, $warn-color, $font-family) {

  .updateMessage {
    background-color: white;
    color: $primary-color;
    width: 350px;

    .title-text {
      margin-right: .7rem;
    }

    .close-btn {
      position: absolute;
      top: -7px;
      right: -7px;
    }

    .close-icon {
      color: #444444;
    }
  }

  @each $type,
  $styleMap in $messageBarTypes {
    .message-bar-#{$type} {
      @include mat-snack-bar-container;
      background-color: map-get($map: $styleMap, $key: bg);
      color: map-get($map: $styleMap, $key: textColor);

      .message-bar {
        &__type-icon-wrapper {
          background-color: map-get($map: $styleMap, $key: iconWrapperBg);
        }

        &__type-icon {
          color: map-get($map: $styleMap, $key: iconColor);
        }

        &__close-icon {
          color: map-get($map: $styleMap, $key: textColor);
        }
      }

    }
  }

  .okMessage {
    @include mat-snack-bar-container;
    background-color: #DCFFEC;

    mat-icon {
      color: #95D3B2;
    }

    color: #444444;
  }

  .infoMessage {
    @include mat-snack-bar-container;
    background-color: #FFFFFF;
    color: #444444;
  }


}
