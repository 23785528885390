.hml-dropdown {
  &-like-text {
    padding: 0;
    font-weight: inherit;

    .mat-form-field-flex {
      display: flex;
    }

    .mat-select, .mat-select-trigger {
      display: block;
    }

    .mat-form-field-wrapper {
      background: transparent;
      border: none;
      width: auto;
    }

    .mat-select-value {
      color: inherit;
      max-width: none;
      width: auto;
    }

    .mat-form-field-infix {
      width: auto;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      height: auto;
    }

    .mat-select-arrow {
      color: inherit;
    }
  }
}
