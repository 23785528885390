@import "abstract";

.mat-card {
  font-family: $font-family-base;
  font-weight: 400;
}
.mat-card-title {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;

  /* Text Gray */

  color: #444444;
}
.mat-card-header .mat-card-title {
  font-size: 18px;
}

