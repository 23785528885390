@import "abstract";

html,
body,
.h100 {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family-base;
  font-size: $mainFontSize;
  background-color: $colorPurpleLightBg;
}

button {
  font-family: $font-family-base;
}

.content-page {
  &__header {
    display: flex;
    padding: 1rem 1rem 0;
    &-button-holder {
      margin-left: auto;
    }
  }
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}

.table-view {
  &__icon {
    padding-top: 8px;
  }

  .table-paginator {
    width: 100%;
    max-width: 1100px;
    min-width: 1100px;
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.hovered-button-wrapper:hover .hovered-button {
  opacity: 1;
}

.hovered-button {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 24px;
  opacity: 0.1;
  transition: 400ms ease-in-out;
  box-shadow: none;
  color: $mainBackgroundColor;
  background-color: $primaryButtonBackgroundColor;
}

.flex {
  display: flex;
  &-center-x {
    justify-content: center;
  }
  &-space-between {
    justify-content: space-between;
  }
  &-wrap {
    flex-wrap: wrap;
  }
}

.absolute {
  position: absolute;
  width: 100%;
}

.helper-red {
  color: $colorDanger;
  font-size: 14px;
}

.toolbar {
  height: 56px;
  padding: 0 1.5rem;
  background-color: $mainBackgroundColor;
  user-select: none;
  .navigate-wrapper {
    cursor: pointer;
    span {
      font-size: 0.875rem;
      padding-bottom: 3px;
      color: $secondaryFontColor;
      text-decoration: underline;
      text-underline-position: under;
    }
    ::before {
      color: $secondaryFontColor;
    }
  }
}

.view-form {
  display: flex;
  font-size: 14px;
  padding-bottom: 5px;
  &__label {
    width: 250px;
  }
  &__value {
    width: 350px;
    &--200 {
      width: 200px;
    }
  }
}

.login-title {
  margin: 20px 0 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #444444;
}

.muted {
  color: gray;
}

.table-view {
  &--100 {
    table {
      max-width: 100% !important;
      min-width: unset;
    }
    .table-paginator {
      width: 100%;
      max-width: unset;
      min-width: unset;
    }
  }
  table {
    width: 100%;
    max-width: 1100px;
    .chip {
      min-width: 58px !important;
      max-width: 58px !important;
    }
  }

  th.center {
    padding: 0 0 0 24px;
  }
}

.text {
  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-justify {
    text-align: justify;
  }
}

.profile-info {
  top: 65px;
  right: 130px;
}
