.statistics-card {
  padding: 1.25rem 1.5rem .625rem 1.25rem;
  color: $mainFontColor;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 3px 8px 0px #00000014;

  &__view {
    color: $secondaryFontColor;
    font-size: 10px;
  }

  &__header {
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__body {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 113%;
    font-family: $font-family-head;
  }

  table {
    width: 100%;

    td {
      height: 21px;
      vertical-align: bottom;
    }
  }

  &-property {
    color: $secondaryFontColor;
    font-size: .875rem;
    line-height: normal;
  }

  &-value {
    font-size: .875rem;
    font-weight: 700;
    line-height: normal;
  }
}
