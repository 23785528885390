@import "abstract";

.hml-paginator {
  font-size: 10px;
  line-height: normal;
  color: $secondaryFontColor;
  border-radius: 0 0 10px 10px;

  .mat-paginator-container {
    min-height: 36px;
    justify-content: flex-end;
    padding: 0;
  }

  .mat-paginator-icon {
    width: 20px;
  }

  .mat-icon-button {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }

  .mat-form-field {
    padding-top: 0;
    padding-bottom: 3px;
  }

  .mat-select-trigger {
    font-size: 10px;
  }

  .mat-form-field-wrapper {
    border-color: transparent;
  }

  .mat-select-value, .mat-select-arrow {
    color: $secondaryFontColor;
    text-align: center;
  }

  .mat-select {
    padding: 0;
  }

  .mat-paginator-page-size-label {
    margin: 0;
  }

  .mat-paginator-page-size {
    margin-right: 5%;
  }
}

.cars-table-pagination {
  .mat-paginator-outer-container {
    padding-right: 90px;
  }
}

.monitoring-paginator {
  .mat-paginator-container {
    justify-content: space-between;
  }
}

.app-styled-paginator {
  .mat-paginator-range-actions {
    .mat-paginator-range-label {
      order: 1;
      margin: 5px 0 0 0;
      .paginator-page {
        cursor: pointer;
        &_active {
          color: black;
        }
        margin: 0 5px;
      }
    }

    button {
      &:first-of-type {
        order: 0;
      }

      &:last-of-type {
        order: 2;
      }
    }
  }
}
