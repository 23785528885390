.hml-dialog {
  .mat-dialog-container {
    border-radius: 10px;
    padding: 1.625rem 1.875rem;
    position: relative;
  }
}
.mat-dialog-container {
  position: relative;
}

.dashboard-management-dialog {
  .mat-dialog-container {
    height: auto;
    width: 536px;
    border-radius: 10px 0 0 10px;
    position: fixed;
    top: 76px;
    bottom: 0;
    right: 0;
  }
}

.add-driver-form-dialog {
  .mat-dialog-container {
    border-radius: 10px;
    padding: 0;
  }
}
