@font-face {
    font-family: 'Hyundai Sans Head';
    src: url('HyundaiSansHeadMedium.eot');
    src: local('Hyundai Sans Head Medium'), local('HyundaiSansHeadMedium'),
        url('HyundaiSansHeadMedium.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansHeadMedium.woff2') format('woff2'),
        url('HyundaiSansHeadMedium.woff') format('woff'),
        url('HyundaiSansHeadMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hyundai Sans Head';
    src: url('HyundaiSansHead-Light.eot');
    src: local('Hyundai Sans Head Light'), local('HyundaiSansHead-Light'),
        url('HyundaiSansHead-Light.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansHead-Light.woff2') format('woff2'),
        url('HyundaiSansHead-Light.woff') format('woff'),
        url('HyundaiSansHead-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hyundai Sans Head';
    src: url('HyundaiSansHead-Bold.eot');
    src: local('Hyundai Sans Head Bold'), local('HyundaiSansHead-Bold'),
        url('HyundaiSansHead-Bold.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansHead-Bold.woff2') format('woff2'),
        url('HyundaiSansHead-Bold.woff') format('woff'),
        url('HyundaiSansHead-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hyundai Sans Head';
    src: url('HyundaiSansHead.eot');
    src: local('Hyundai Sans Head'), local('HyundaiSansHead'),
        url('HyundaiSansHead.eot?#iefix') format('embedded-opentype'),
        url('HyundaiSansHead.woff2') format('woff2'),
        url('HyundaiSansHead.woff') format('woff'),
        url('HyundaiSansHead.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

