.notifications-navbar-btn {
  .mat-badge-medium
  .mat-badge-content{
    width: 13px;
    height: 13px;
    background: $notificationBadgeIcon;
  }
  .mat-badge-medium.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
    right: -3px;
    top: -3px;
  }
}

.notification-message-alert-dialog {
  .mat-dialog-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mat-button-focus-overlay {
    display: none;
  }
}

.notifications-setting-table-view, 
.notify-table-view {
  table {
    .mat-sort-header-sorted {
      color: $primaryButtonBackgroundColor;
    }
  
    tr:not(:hover) {
      .add-button, .edit-button  {
  
        opacity: 0.1 !important;
        transition: 400ms ease-in-out;
        box-shadow: none;
      }
    }
  }
}

.notification-page {
  .mat-drawer-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.notifications-filters {
  .search-field .mat-form-field-wrapper {
    width: 273px;
    height: 39px;
    display: flex;
    align-items: center;
    border: none;
  }
}
