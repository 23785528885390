.hml-button {
  width: 100%;
  padding: 0.75rem;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;

  font-size: 1rem;
  font-weight: 400;
  line-height: normal;

  &-primary {
    background: $primaryBtnBg;
    color: $primaryBtnColor;
    border: 1px solid $primaryBtnBorder;

    &:hover {
      background: $primaryBtnBgHover;
      color: $primaryBtnColorHover;
      border: 1px solid $primaryBtnBorderHover;
    }

    &:disabled,
    &.disabled {
      pointer-events: none;
      background: $primaryBtnBgDisabled;
      color: $primaryBtnColorDisabled;
      border: 1px solid $primaryBtnBorderDisabled;
    }
  }

  &-custom-color {
    background: var(--color);
    color: $primaryBtnColor;
    border: 1px solid var(--color);

    &:hover {
      background: $primaryBtnBgHover;
      color: $primaryBtnColorHover;
      border: 1px solid $primaryBtnBorderHover;
    }

    &:disabled,
    &.disabled {
      pointer-events: none;
      background: $primaryBtnBgDisabled;
      color: $primaryBtnColorDisabled;
      border: 1px solid $primaryBtnBorderDisabled;
    }
  }

  &-secondary {
    background: $secondaryBtnBg;
    color: $secondaryBtnColor;
    border: 1px solid $secondaryBtnBorder;

    &:hover {
      background: $secondaryBtnBgHover;
      color: $secondaryBtnColorHover;
      border: 1px solid $secondaryBtnBorderHover;
    }

    &:disabled,
    &.disabled {
      pointer-events: none;
      background: $secondaryBtnBgDisabled;
      color: $secondaryBtnColorDisabled;
      border: 1px solid $secondaryBtnBorderDisabled;
    }
  }
}

.icon-settings {
  .mat-button-focus-overlay {
    display: none;
  }
}

.cdk-overlay-pane {
  .hml-button {
    font-size: 0.875rem;
  }
}
