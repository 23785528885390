@use '@angular/material' as mat;
@import "common.classes";
@import 'scrollbar';
@import 'mat-sort';
@import 'snackbars';
@import 'table';
$primary-color: none !default;
$accent-color: none !default;
$warn-color: none !default;
@mixin fleet-theme($theme) {

  $primary-color: mat.get-color-from-palette(map-get($theme, primary));
  $primary-color-50: mat.get-color-from-palette(map-get($theme, primary),50);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent));
  $warn-color: mat.get-color-from-palette(map-get($theme, warn));

  $font-family: $font-family-base;
  @include common($primary-color, $accent-color, $warn-color, $font-family);
  @include scrollbar($primary-color, $accent-color, $warn-color, $font-family);
  @include mat-sort($primary-color, $accent-color, $warn-color, $font-family);
  @include mat-snackbars($primary-color, $accent-color, $warn-color, $font-family);
  @include fleet-table($primary-color, $primary-color-50);
}
