.mat-mini-fab {
  width: 25px !important;
  height: 25px !important;
  line-height: 25px !important;

  .mat-button-wrapper {
    padding: 0 !important;

    .material-icons {
      font-size: 19px !important;
    }
  }
}
