.hml-maps-menu {
  position: absolute;
  background: white;
  font-size: 0.75rem;
  font-weight: bold;
  box-shadow: 1px 3px 3px $cardBoxShadowColor;

  &__button {
    background: white;
    padding: 0.25rem;
    border: 1px solid $colorPurpleLightBg;
    color: $mainFontColor;
    cursor: pointer;

    &:hover {
      background: $secondaryBtnBgHover;
      color: $secondaryBtnColorHover;
    }
  }
}

.hml-maps-delete-menu {
  position: absolute;
  background: white;
  padding: 0.25rem;
  color: $mainFontColor;
  font-weight: bold;
  border: 1px solid $colorPurpleLightBg;
  font-size: 0.75rem;
  box-shadow: 1px 3px 3px $cardBoxShadowColor;
  margin-top: -10px;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    background: $secondaryBtnBgHover;
    color: $secondaryBtnColorHover;
  }
}
