.mat-select-panel.hml-select-panel {
  border-radius: 0;
  box-shadow: 5px 5px 12px 0px $cardBoxShadowColor;

  .mat-option {
    padding: 0 1.25rem;
    font-weight: 400;
    color: $mainFontColor;

    &.mat-selected:not(.mat-option-disabled) {
      color: white;
      background: $colorViolet;
    }
  }

  .mat-pseudo-checkbox {
    display: none;
  }
}

.mat-select-panel.hml-select-panel-without-centering {
  min-width: calc(100% + 0.5rem) !important;
  position: relative;
  left: 0.5rem;
  top: 32px;

  .mat-option {
    padding: 0 0.5rem;
  }
}
