.mat-form-field-type-hml-multi-text-input {
  .mat-form-field-wrapper,
  .mat-form-field-infix {
    background: none;
    height: auto;
    border: none;
    border-radius: none;
  }
}

.hml-multi-text {
  .mat-icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 32px;
    line-height: 32px;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mat-icon {
      font-size: 1rem;
      line-height: 1rem;
      width: 1rem;
      height: 1rem;
    }
  }

  &__row {
    border-radius: 6px;
    border: none;
    height: 32px;
    font-family: "Hyundai Sans Text", "SF Compact Display", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;
    position: relative;
  }

  &__control {
    height: 100%;
    width: 100%;
    padding: 0 1.5rem 0 0.5rem;
    border-radius: 4px;
    margin-top: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: #ffffff;
    text-overflow: ellipsis;

    &.ng-invalid.ng-touched {
      background-color: #f4c1d3;
      color: rgba(0, 0, 0, 0.87);
      caret-color: rgba(0, 0, 0, 0.87);
    }
  }

  &__remove-row-btn {
    position: absolute !important;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    color: inherit;
  }

  &__add-row-btn-wrapper {
    text-align: right;
  }
}
