/* You can add global styles to this file, and also import other style files */

//@import "assets/fonts/SFCompactDisplay/SFCompactDisplay.css";
//@import "assets/fonts/fleet-v1.0/style.css";

@import "assets/fonts/hyundai-sans-head/stylesheet.css";
@import "assets/fonts/hyundai-sans-text/stylesheet.css";

@import "assets/fonts/fleet-v2/style.css"; // источник иконок
@import "https://api.mobility.hyundai.ru/public/fonts/fleet.1.2/style.css?ver=1.2"; // еще источник иконок
@import "styles/theme";
@import "apps/flit/src/styles/abstract";
@import "apps/flit/src/styles/common";
@import "apps/flit/src/styles/material-vendor";
@import "../../../node_modules/nouislider/dist/nouislider.css";
