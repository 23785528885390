@use '@angular/material' as mat;
@import "../../../../../node_modules/@angular/material/theming";
@import "abstract";

$form-field-color: #444444;
$form-field-prefix-color: #b8b8b8;
//$form-field-background: #f0f0f0;
$form-field-background: #ffffff;

@mixin fleet-form-fields-theme($theme) {
  $warn: map-get($theme, warn);
  $primary: map-get($theme, primary);
  $hue: 50;
  $form-field-border: mat.get-color-from-palette($primary, 50);
  $warn-color: mat.get-contrast-color-from-palette($warn, $hue);
  $warn-bg: mat.get-color-from-palette($warn, $hue);

  .mat-form-field {
    font-family: $font-family-base;
    //min-height: 66px;
    padding-top: 26px;
    padding-bottom: 8px;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
    //padding: 8px;
  }

  .mat-form-field-infix {
    background: $form-field-background;
    border-radius: 4px;
    border: none;

    height: 32px;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $form-field-color;
  }

  //label
  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 0;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $form-field-color;
    height: 14px;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: none;
    -ms-transform: none;
    width: 133.3333333333%;
  }

  .mat-form-field-label-wrapper {
    position: absolute;
    left: 0;
    box-sizing: content-box;
    width: 100%;
    height: 53px;
    overflow: hidden;
    pointer-events: none;
  }

  .mat-form-field-label-wrapper {
    top: -18px;
    //top: -24px;
    padding-top: 18px;
  }
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    padding-left: 4px;
    color: $form-field-prefix-color;
    //top: -1px;
    align-self: center;
  }
  .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    //margin-top: 0.5416666667em;
    top: calc(100% - 55px);
    width: 60%;
    left: 40%;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $form-field-color;
  }

  .mat-error {
    text-align: end;
    &--left {
      text-align: start;
    }
  }

  .mat-warn .mat-input-element,
  .mat-form-field-invalid .mat-input-element {
    background-color: $warn-bg;
    color: $warn-color;
    caret-color: $warn-color;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
    background: $form-field-background;
    border-radius: 6px;
    border-color: $form-field-border;
    border-width: 1px;
    border-style: solid;
  }

  .select-field .mat-form-field-wrapper {
    display: flex;
    align-items: center;
    width: 254px;
    height: 39px;
    border: none;
    padding-left: 1rem;
    .mat-select-value-text {
      padding-left: 0.625rem;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: unset;
    display: none;
    //left: 4px;
    //width: calc(100% - 8px);
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: none;
    -ms-transform: none;
    /* width: 133.3333433333%; */
  }
}
