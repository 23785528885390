@import "abstract";

.btn-icon-text {
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  font-weight: 400;
  color: $mainFontColor;

  &__icon,
  &__icon.mat-icon {
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 50%;
    background: $colorPurpleLightBg;
    margin-right: 0.625rem;
  }

  &:disabled,
  &.disabled {
    .btn-icon-text__icon {
      background: $colorPurpleLightBg;
      color: $secondaryFontColor;
    }
  }

  &-green {
    .btn-icon-text__icon {
      color: $colorGreen;
      background: $colorPurpleLightBg;
    }

    &:hover,
    &.active {
      .btn-icon-text__icon {
        background: $colorGreen;
        color: white;
      }
    }
  }

  &-violet {
    .btn-icon-text__icon {
      color: $colorViolet;
      background: $colorPurpleLightBg;
    }

    &:hover,
    &.active {
      .btn-icon-text__icon {
        background: $colorViolet;
        color: white;
      }
    }
  }

  &-red {
    .btn-icon-text__icon {
      color: $colorRed;
      background: $colorPurpleLightBg;
    }

    &:hover,
    &.active {
      .btn-icon-text__icon {
        background: $colorRed;
        color: white;
      }
    }
  }
}
