.hml-card {
  background: $cardBg;
  border-radius: 10px;
  position: relative;

  &__header {
    padding: 2.375rem 1.5rem 0 1.125rem;
  }

  &__body {
    padding: 0 1.5rem 1rem 1.125rem;
  }

  &__footer {
    padding: 0 1.5rem 0 1.125rem;

    &._border-top {
      border-top: 1px solid $colorWhitesmoke;
    }
  }
}
