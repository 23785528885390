.hml-buttons-panel {
  background: $colorPurpleLightBg;
  border-radius: 6px;
  padding: 0 0.125rem;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;

  .hml-button {
    background: transparent;
    color: $secondaryFontColor;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0.125rem 0;
    padding: 0 1rem;
    border-radius: 6px;
    flex: 0;
    white-space: nowrap;

    &.active {
      margin: 0.125rem 0 0.25rem;
      background: white;
      color: $mainFontColor;
      box-shadow: 0px 1.0900901556px 2.1801803112px 0px $toggleBtnShadowColor,
        0px 0.5450450778px 1.0900901556px 0px $toggleBtnShadowColor;
    }
  }

  .circle-colored {
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: var(--color);
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    flex: 0 0 auto;
  }
}
