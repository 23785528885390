

.mat-paginator-page-size {
  align-items: center!important;
}

.spinner--white {
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: white;
  }
}
