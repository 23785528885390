
.mat-input-element {
  height: 100%;
  width: calc(100% - 16px);
  padding: 0 8px;
  border-radius: 4px;
}

input.mat-input-element {
  height: 100%;
  width: calc(100% - 16px);
  padding: 0 8px;
  border-radius: 4px;
  margin-top: 0;
}
