@font-face {
  font-family: 'fleet-v2';
  src:  url('fonts/fleet-v2.eot?mn4gtz');
  src:  url('fonts/fleet-v2.eot?mn4gtz#iefix') format('embedded-opentype'),
    url('fonts/fleet-v2.ttf?mn4gtz') format('truetype'),
    url('fonts/fleet-v2.woff?mn4gtz') format('woff'),
    url('fonts/fleet-v2.svg?mn4gtz#fleet-v2') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fleet-v2-"], [class*=" fleet-v2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fleet-v2' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fleet-v2-delete:before {
  content: "\e90b";
}
.fleet-v2-edit:before {
  content: "\e90c";
}
.fleet-v2-ruler:before {
  content: "\e90a";
}
.fleet-v2-geozones:before {
  content: "\e909";
}
.fleet-v2-geozone-polygon:before {
  content: "\e907";
}
.fleet-v2-geozone-circle:before {
  content: "\e908";
}
.fleet-v2-ggm:before {
  content: "\e906";
}
.fleet-v2-check:before {
  content: "\e900";
}
.fleet-v2-download:before {
  content: "\e901";
}
.fleet-v2-levels:before {
  content: "\e902";
}
.fleet-v2-minus-circle:before {
  content: "\e903";
}
.fleet-v2-print:before {
  content: "\e904";
}
.fleet-v2-signal:before {
  content: "\e905";
}
