.hml-radio-button {
  .mat-radio-container,
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: 24px;
    height: 24px;
  }

  .mat-radio-outer-circle {
    border: 1px solid $radioBtnBorderColor;
  }

  .mat-radio-ripple {
    display: none !important;
  }

  &.mat-radio-button.mat-accent {
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $colorViolet;
      }
    }

    .mat-radio-inner-circle {
      background-color: $colorViolet;
    }
  }
}
