@mixin mat-sort($primary-color,$accent-color,$warn-color, $font-family) {
  .mat-sort-header-stem {
    width: 0 !important;
  }

  .mat-sort-header-pointer-left, .mat-sort-header-pointer-right {
    display: none !important;
  }

  .mat-sort-header-pointer-middle{
    width: 0 !important;
    height: 0 !important;
  }

  .mat-sort-header-arrow {
    margin: 0 0 0 10px !important;
    .mat-sort-header-indicator {
      &::before {
        content: url("/assets/icons/sort-icon.svg");
        position: absolute;
        transform: translate(-60%, 35%) !important;
      }
    }
  }

  [aria-sort="ascending"] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          content: url("/assets/icons/sort-arrow.png");
          position: absolute;
          transform: translate(-60%, 10%) !important;
        }
      }
    }
  }

  [aria-sort="descending"] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          content: url("/assets/icons/sort-arrow.png");
          position: absolute;
          transform: translate(-60%, -20%) rotate(180deg) !important;
        }
      }
    }
  }
}
