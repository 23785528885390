@import '../../../../../node_modules/@angular/material/theming';

.mat-select{
  width: calc(100% - 8px)!important;
  padding-left: 8px;

  height: inherit;
  .mat-select-trigger {
    height: inherit;
    width: 100%;
    .mat-select-value {
      vertical-align: middle;
    }
  }
}
.arrow .mat-select-arrow {
  display: none;
}
