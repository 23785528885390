.mat-paginator {
  .mat-form-field {
    padding-top: 5px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 3px;
    height: 19px !important;
  }
}
