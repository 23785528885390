.org-registration-profile {
  .hml-form-field:not(.hml-form-field__textarea) {
    display: flex;
    height: 38px;

    .mat-form-field-wrapper {
      width: 100%;
    }
  }
}
