@mixin scrollbar($primary-color,$accent-color,$warn-color, $font-family) {

  ::-webkit-scrollbar-button {
    width: 0.4vw;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    //background: #F5F5F5;
    border-radius: 0.3vw;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0.4vw;
    background-color: $primary-color;
    border-radius: 0.2vw;
    width: 0.4vw;
    height: 0.4vw;
  }

  ::-webkit-resizer {
    width: 0.3vw;
    height: 0;
  }

  ::-webkit-scrollbar {
    width: 0.3vw;
    height: 0.3vw;
  }
}
